exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-as-a-service-js": () => import("./../../../src/pages/data-as-a-service.js" /* webpackChunkName: "component---src-pages-data-as-a-service-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-event-details-js": () => import("./../../../src/pages/eventDetails.js" /* webpackChunkName: "component---src-pages-event-details-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/events/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-events-markdown-remark-parent-file-name-js" */),
  "component---src-pages-free-data-js": () => import("./../../../src/pages/free_data.js" /* webpackChunkName: "component---src-pages-free-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsandmedia-js": () => import("./../../../src/pages/newsandmedia.js" /* webpackChunkName: "component---src-pages-newsandmedia-js" */),
  "component---src-pages-newsandmedia-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/newsandmedia/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-newsandmedia-markdown-remark-parent-file-name-js" */),
  "component---src-pages-nitt-js": () => import("./../../../src/pages/nitt.js" /* webpackChunkName: "component---src-pages-nitt-js" */),
  "component---src-pages-open-data-js": () => import("./../../../src/pages/open-data.js" /* webpackChunkName: "component---src-pages-open-data-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-markdown-remark-parent-file-name-js": () => import("./../../../src/pages/projects/{MarkdownRemark.parent__(File)__name}.js" /* webpackChunkName: "component---src-pages-projects-markdown-remark-parent-file-name-js" */)
}

